/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ApplePay from 'Style/icons/logo/apple-pay-logo.png';
import Blik from 'Style/icons/logo/blik.svg';
import Dpd from 'Style/icons/logo/dpd.svg';
import Eps from 'Style/icons/logo/eps.png';
import Giropay from 'Style/icons/logo/giropay.svg';
import Gls from 'Style/icons/logo/gls.svg';
import GooglePay from 'Style/icons/logo/google-pay.svg';
import InPost from 'Style/icons/logo/in-post.svg';
import Klarna from 'Style/icons/logo/klarna-logo.png';
import MasterCard from 'Style/icons/logo/master-card.svg';
import Opineo1 from 'Style/icons/logo/opineo-1.png';
import Opineo2 from 'Style/icons/logo/opineo-2.png';
import PayPal from 'Style/icons/logo/pay-pal.svg';
import PayPo from 'Style/icons/logo/pay-po.svg';
import Przelewy24 from 'Style/icons/logo/przelewy24.svg';
import Sepa from 'Style/icons/logo/sepa.webp';
import Visa from 'Style/icons/logo/visa.svg';

export const FOOTER_CONTENT = 'footer';
export const NEWSLETTER_CONTENT = 'newsletter';
export const RENDER_NEWSLETTER = 'render_newsletter';

export const NEWSLETTER_COLUMN = {
    title: __('Newsletter'),
    columnActiveKey: 'newsletterActive',
    items: [
        {
            render: RENDER_NEWSLETTER,
        },
    ],
};

export const BOTTOM_CONTENT = [
    {
        title: __('Badges and awards'),
        payments: false,
        items: [
            {
                src: Opineo1,
                alt: 'Opineo',
                width: 104,
                height: 61,
            },
            {
                src: Opineo2,
                alt: __('Listen my customers'),
                width: 58,
                height: 61,
            },
        ],
    },
    {
        title: __('Logistics'),
        payments: false,
        items: [
            {
                src: Gls,
                alt: 'GLS',
                width: 89,
                height: 30,
            },
            {
                src: InPost,
                alt: 'InPost',
                width: 50,
                height: 30,
            },
            {
                src: Dpd,
                alt: 'DPD',
                width: 63,
                height: 30,
            },
        ],
    },
    {
        title: __('Payments'),
        payments: true,
        items: [
            {
                src: PayPo,
                alt: 'PayPO',
                width: 89,
                height: 30,
            },
            {
                src: Blik,
                alt: 'Blik',
                width: 62,
                height: 30,
            },
            {
                src: GooglePay,
                alt: 'GooglePay',
                width: 61,
                height: 30,
            },
            {
                src: PayPal,
                alt: 'PayPal',
                width: 92,
                height: 30,
            },
            {
                src: Przelewy24,
                alt: 'Przelewy24',
                width: 69,
                height: 30,
            },
            {
                src: MasterCard,
                alt: 'MasterCard',
                width: 40,
                height: 30,
            },
            {
                src: Visa,
                alt: 'Visa logo',
                width: 77,
                height: 30,
            },
        ],
    },
];

export const MAZOVIA_STORE = 'de_de';

export const BOTTOM_CONTENT_DE = [
    {
        title: __('Logistics'),
        payments: false,
        items: [
            {
                src: Gls,
                alt: 'GLS',
                width: 89,
                height: 30,
            },
            {
                src: Dpd,
                alt: 'DPD',
                width: 63,
                height: 30,
            },
        ],
    },
    {
        title: __('Payments'),
        payments: true,
        items: [
            {
                src: PayPal,
                alt: 'PayPal',
                width: 106,
                height: 30,
            },
            {
                src: ApplePay,
                alt: 'ApplePay',
                width: 73,
                height: 30,
            },
            {
                src: GooglePay,
                alt: 'GooglePay',
                width: 76,
                height: 30,
            },
            {
                src: Giropay,
                alt: 'Giropay',
                width: 65,
                height: 30,
            },
            {
                src: Eps,
                alt: 'Eps',
                width: 120,
                height: 30,
            },
            {
                src: Klarna,
                alt: 'Klarna',
                width: 120,
                height: 30,
            },
            {
                src: Sepa,
                alt: 'Sepa',
                width: 110,
                height: 30,
            },
        ],
    },
];
